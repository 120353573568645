
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "path": "/home",
    "label": "Home",
    "icon": "home",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/jabatanjudge",
    "label": "Jabatan Judge",
    "icon": "art_track",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/jabatanroutesetter",
    "label": "Jabatan Routesetter",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/kejuaraan",
    "label": "Kejuaraan",
    "icon": "accessibility",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/pengguna",
    "label": "Pengguna",
    "icon": "person",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/pengprov",
    "label": "Pengprov",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/permissions",
    "label": "Permissions",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/reportjudgec1",
    "label": "Report Judge C1",
    "icon": "add_box",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/roles",
    "label": "Roles",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/statusreportjudgec1",
    "label": "Status Report Judge C1",
    "icon": "verified_user",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/levelsertifikasi",
    "label": "Level Sertifikasi",
    "icon": "trending_up",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/berita",
    "label": "News",
    "icon": "notifications_none",
    "iconcolor": "",
    "target": "",
    "submenu": []
  }
],
	beritalistheader: [
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	jabatanjudgelistheader: [
  {
    "label": "Nama Jabatan",
    "align": "left",
    "sortable": false,
    "name": "nama_jabatan",
    "field": "nama_jabatan"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	jabatanroutesetterlistheader: [
  {
    "label": "Idjabatan",
    "align": "left",
    "sortable": false,
    "name": "idjabatan",
    "field": "idjabatan"
  },
  {
    "label": "Nama Jabatan",
    "align": "left",
    "sortable": false,
    "name": "nama_jabatan",
    "field": "nama_jabatan"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	kejuaraanlistheader: [
  {
    "label": "Kejuaraan / Kegiatan / Kursus",
    "align": "left",
    "sortable": false,
    "name": "nama_kejuaraan",
    "field": "nama_kejuaraan"
  },
  {
    "label": "Tahun",
    "align": "left",
    "sortable": false,
    "name": "thn_kejuaraan",
    "field": "thn_kejuaraan"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	levelsertifikasilistheader: [
  {
    "label": "Jabatan",
    "align": "left",
    "sortable": false,
    "name": "jabatan",
    "field": "jabatan"
  },
  {
    "label": "Sertifikasi",
    "align": "left",
    "sortable": false,
    "name": "sertifikasi",
    "field": "sertifikasi"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	jabatan: [    
{value: "Route Setter", label: "Route Setter"},
	{value: "Judge", label: "Judge"}
    ],
	penggunalistheader: [
  {
    "label": "Photo",
    "align": "left",
    "sortable": false,
    "name": "photo",
    "field": "photo"
  },
  {
    "label": "User Role Id",
    "align": "left",
    "sortable": false,
    "name": "user_role_id",
    "field": "user_role_id"
  },
  {
    "label": "Nama Lengkap",
    "align": "left",
    "sortable": false,
    "name": "nama_lengkap",
    "field": "nama_lengkap"
  },
  {
    "label": "No Hp",
    "align": "left",
    "sortable": false,
    "name": "no_hp",
    "field": "no_hp"
  },
  {
    "label": "Alamat",
    "align": "left",
    "sortable": false,
    "name": "alamat",
    "field": "alamat"
  },
  {
    "label": "Pengprov",
    "align": "left",
    "sortable": false,
    "name": "pengprov",
    "field": "pengprov"
  },
  {
    "label": "Sertifikasi",
    "align": "left",
    "sortable": false,
    "name": "sertifikasi",
    "field": "sertifikasi"
  },
  {
    "label": "Kursus",
    "align": "left",
    "sortable": false,
    "name": "kursus",
    "field": "kursus"
  },
  {
    "label": "Refresh",
    "align": "left",
    "sortable": false,
    "name": "refresh",
    "field": "refresh"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	pengprovlistheader: [
  {
    "label": "Pengprov Kode",
    "align": "left",
    "sortable": false,
    "name": "pengprov_kode",
    "field": "pengprov_kode"
  },
  {
    "label": "Pengprov",
    "align": "left",
    "sortable": false,
    "name": "pengprov",
    "field": "pengprov"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	permissionslistheader: [
  {
    "label": "Permission Id",
    "align": "left",
    "sortable": false,
    "name": "permission_id",
    "field": "permission_id"
  },
  {
    "label": "Permission",
    "align": "left",
    "sortable": false,
    "name": "permission",
    "field": "permission"
  },
  {
    "label": "Role Id",
    "align": "left",
    "sortable": false,
    "name": "role_id",
    "field": "role_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	reportjudgec1listheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "masterdetailbtn",
    "field": ""
  },
  {
    "label": "Id User",
    "align": "left",
    "sortable": false,
    "name": "id_user",
    "field": "id_user"
  },
  {
    "label": "Kejuaraan / Kegiatan",
    "align": "left",
    "sortable": false,
    "name": "kejuaraan",
    "field": "kejuaraan"
  },
  {
    "label": "Thn Tugas",
    "align": "left",
    "sortable": false,
    "name": "thn_tugas",
    "field": "thn_tugas"
  },
  {
    "label": "Jabatan Tugas",
    "align": "left",
    "sortable": false,
    "name": "jabatan_tugas",
    "field": "jabatan_tugas"
  },
  {
    "label": "Bukti Tugas",
    "align": "left",
    "sortable": false,
    "name": "bukti_tugas",
    "field": "bukti_tugas"
  },
  {
    "label": "Tgl Update",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "statusreportjudgec1_nama_status",
    "field": "statusreportjudgec1_nama_status"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tingkat: [    
{value: "Internasional", label: "Internasional"},
	{value: "Nasional", label: "Nasional"},
	{value: "Provinsi", label: "Provinsi"},
	{value: "Kabupaten atau Kota", label: "Kabupaten atau Kota"}
    ],
	roleslistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "masterdetailbtn",
    "field": ""
  },
  {
    "label": "Role Id",
    "align": "left",
    "sortable": false,
    "name": "role_id",
    "field": "role_id"
  },
  {
    "label": "Role Name",
    "align": "left",
    "sortable": false,
    "name": "role_name",
    "field": "role_name"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	namaStatus: [    
{value: "Pending", label: "Pending"},
	{value: "Disetujui", label: "Disetujui"},
	{value: "Ditolak", label: "Ditolak"}
    ],
	statusreportjudgec1listheader: [
  {
    "label": "Status Data",
    "align": "left",
    "sortable": false,
    "name": "nama_status",
    "field": "nama_status"
  },
  {
    "label": "Report",
    "align": "left",
    "sortable": false,
    "name": "id_report",
    "field": "id_report"
  },
  {
    "label": "Nama",
    "align": "left",
    "sortable": false,
    "name": "id_user",
    "field": "id_user"
  },
  {
    "label": "Kejuaraan/Kegiatan",
    "align": "left",
    "sortable": false,
    "name": "id_kejuaraan",
    "field": "id_kejuaraan"
  },
  {
    "label": "Thn Tugas",
    "align": "left",
    "sortable": false,
    "name": "reportjudgec1_thn_tugas",
    "field": "reportjudgec1_thn_tugas"
  },
  {
    "label": "Bukti Tugas",
    "align": "left",
    "sortable": false,
    "name": "reportjudgec1_bukti_tugas",
    "field": "reportjudgec1_bukti_tugas"
  },
  {
    "label": "Tgl Input",
    "align": "left",
    "sortable": false,
    "name": "reportjudgec1_date_created",
    "field": "reportjudgec1_date_created"
  },
  {
    "label": "Tgl Update",
    "align": "left",
    "sortable": false,
    "name": "reportjudgec1_date_updated",
    "field": "reportjudgec1_date_updated"
  },
  {
    "label": "Catatan",
    "align": "left",
    "sortable": false,
    "name": "reportjudgec1_catatan",
    "field": "reportjudgec1_catatan"
  },
  {
    "label": "Tingkat",
    "align": "left",
    "sortable": false,
    "name": "reportjudgec1_tingkat",
    "field": "reportjudgec1_tingkat"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],

    exportFormats: {
        print: {
			label: 'Print',
			color: 'blue',
            icon: 'print',
            id: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			color: 'red',
            icon: 'picture_as_pdf',
            id: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
			color: 'green',
            icon: 'grid_on',
            id: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
			color: 'teal',
            icon: 'grid_on',
            id: 'csv',
            ext: 'csv',
        },
    },
    
}