
import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'

import { useAuth } from 'src/composables/auth';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [{
	name: 'main',
	path: '/',
	component: () => import('layouts/MainLayout.vue'),
	children: [
		//Dashboard routes


//berita routes
		{
			path: '/berita/:fieldName?/:fieldValue?',
			name: 'beritalist',
			component: () => import('pages/berita/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/berita/view/:id', 
			name: 'beritaview', 
			component: () => import('pages/berita/view.vue'), 
			props: true 
		},
		{ 
			path: '/berita/add', 
			name: 'beritaadd', 
			component: () => import('pages/berita/add.vue'), 
			props: true 
		},
		{ 
			path: '/berita/edit/:id', 
			name: 'beritaedit', 
			component: () => import('pages/berita/edit.vue'), 
			props: true 
		},

//jabatanjudge routes
		{
			path: '/jabatanjudge/:fieldName?/:fieldValue?',
			name: 'jabatanjudgelist',
			component: () => import('pages/jabatanjudge/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/jabatanjudge/view/:id', 
			name: 'jabatanjudgeview', 
			component: () => import('pages/jabatanjudge/view.vue'), 
			props: true 
		},
		{ 
			path: '/jabatanjudge/add', 
			name: 'jabatanjudgeadd', 
			component: () => import('pages/jabatanjudge/add.vue'), 
			props: true 
		},
		{ 
			path: '/jabatanjudge/edit/:id', 
			name: 'jabatanjudgeedit', 
			component: () => import('pages/jabatanjudge/edit.vue'), 
			props: true 
		},

//jabatanroutesetter routes
		{
			path: '/jabatanroutesetter/:fieldName?/:fieldValue?',
			name: 'jabatanroutesetterlist',
			component: () => import('pages/jabatanroutesetter/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/jabatanroutesetter/view/:id', 
			name: 'jabatanroutesetterview', 
			component: () => import('pages/jabatanroutesetter/view.vue'), 
			props: true 
		},
		{ 
			path: '/jabatanroutesetter/add', 
			name: 'jabatanroutesetteradd', 
			component: () => import('pages/jabatanroutesetter/add.vue'), 
			props: true 
		},
		{ 
			path: '/jabatanroutesetter/edit/:id', 
			name: 'jabatanroutesetteredit', 
			component: () => import('pages/jabatanroutesetter/edit.vue'), 
			props: true 
		},

//kejuaraan routes
		{
			path: '/kejuaraan/:fieldName?/:fieldValue?',
			name: 'kejuaraanlist',
			component: () => import('pages/kejuaraan/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/kejuaraan/view/:id', 
			name: 'kejuaraanview', 
			component: () => import('pages/kejuaraan/view.vue'), 
			props: true 
		},
		{ 
			path: '/kejuaraan/add', 
			name: 'kejuaraanadd', 
			component: () => import('pages/kejuaraan/add.vue'), 
			props: true 
		},
		{ 
			path: '/kejuaraan/edit/:id', 
			name: 'kejuaraanedit', 
			component: () => import('pages/kejuaraan/edit.vue'), 
			props: true 
		},

//levelsertifikasi routes
		{
			path: '/levelsertifikasi/:fieldName?/:fieldValue?',
			name: 'levelsertifikasilist',
			component: () => import('pages/levelsertifikasi/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/levelsertifikasi/view/:id', 
			name: 'levelsertifikasiview', 
			component: () => import('pages/levelsertifikasi/view.vue'), 
			props: true 
		},
		{ 
			path: '/levelsertifikasi/add', 
			name: 'levelsertifikasiadd', 
			component: () => import('pages/levelsertifikasi/add.vue'), 
			props: true 
		},
		{ 
			path: '/levelsertifikasi/edit/:id', 
			name: 'levelsertifikasiedit', 
			component: () => import('pages/levelsertifikasi/edit.vue'), 
			props: true 
		},

//pengguna routes
		{
			path: '/pengguna/:fieldName?/:fieldValue?',
			name: 'penggunalist',
			component: () => import('pages/pengguna/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/pengguna/view/:id', 
			name: 'penggunaview', 
			component: () => import('pages/pengguna/view.vue'), 
			props: true 
		},
		{ 
			path: '/index/register', 
			name: 'penggunauserregister', 
			component: () => import('pages/index/userregister.vue'), 
			props: true 
		},
		{ 
			path: '/account/edit', 
			name: 'penggunaaccountedit', 
			component: () => import('pages/account/accountedit.vue'), 
			props: true 
		},
		{ 
			path: '/account', 
			name: 'penggunaaccountview', 
			component: () => import('pages/account/accountview.vue'), 
			props: true 
		},
		{ 
			path: '/pengguna/add', 
			name: 'penggunaadd', 
			component: () => import('pages/pengguna/add.vue'), 
			props: true 
		},
		{ 
			path: '/pengguna/edit/:id', 
			name: 'penggunaedit', 
			component: () => import('pages/pengguna/edit.vue'), 
			props: true 
		},

//pengprov routes
		{
			path: '/pengprov/:fieldName?/:fieldValue?',
			name: 'pengprovlist',
			component: () => import('pages/pengprov/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/pengprov/view/:id', 
			name: 'pengprovview', 
			component: () => import('pages/pengprov/view.vue'), 
			props: true 
		},
		{ 
			path: '/pengprov/add', 
			name: 'pengprovadd', 
			component: () => import('pages/pengprov/add.vue'), 
			props: true 
		},
		{ 
			path: '/pengprov/edit/:id', 
			name: 'pengprovedit', 
			component: () => import('pages/pengprov/edit.vue'), 
			props: true 
		},

//permissions routes
		{
			path: '/permissions/:fieldName?/:fieldValue?',
			name: 'permissionslist',
			component: () => import('pages/permissions/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/permissions/view/:id', 
			name: 'permissionsview', 
			component: () => import('pages/permissions/view.vue'), 
			props: true 
		},
		{ 
			path: '/permissions/add', 
			name: 'permissionsadd', 
			component: () => import('pages/permissions/add.vue'), 
			props: true 
		},
		{ 
			path: '/permissions/edit/:id', 
			name: 'permissionsedit', 
			component: () => import('pages/permissions/edit.vue'), 
			props: true 
		},

//reportjudgec1 routes
		{
			path: '/reportjudgec1/:fieldName?/:fieldValue?',
			name: 'reportjudgec1list',
			component: () => import('pages/reportjudgec1/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/reportjudgec1/view/:id', 
			name: 'reportjudgec1view', 
			component: () => import('pages/reportjudgec1/view.vue'), 
			props: true 
		},
		{ 
			path: '/reportjudgec1/add', 
			name: 'reportjudgec1add', 
			component: () => import('pages/reportjudgec1/add.vue'), 
			props: true 
		},
		{ 
			path: '/reportjudgec1/edit/:id', 
			name: 'reportjudgec1edit', 
			component: () => import('pages/reportjudgec1/edit.vue'), 
			props: true 
		},

//roles routes
		{
			path: '/roles/:fieldName?/:fieldValue?',
			name: 'roleslist',
			component: () => import('pages/roles/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/roles/view/:id', 
			name: 'rolesview', 
			component: () => import('pages/roles/view.vue'), 
			props: true 
		},
		{ 
			path: '/roles/add', 
			name: 'rolesadd', 
			component: () => import('pages/roles/add.vue'), 
			props: true 
		},
		{ 
			path: '/roles/edit/:id', 
			name: 'rolesedit', 
			component: () => import('pages/roles/edit.vue'), 
			props: true 
		},

//statusreportjudgec1 routes
		{
			path: '/statusreportjudgec1/:fieldName?/:fieldValue?',
			name: 'statusreportjudgec1list',
			component: () => import('pages/statusreportjudgec1/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/statusreportjudgec1/view/:id', 
			name: 'statusreportjudgec1view', 
			component: () => import('pages/statusreportjudgec1/view.vue'), 
			props: true 
		},
		{ 
			path: '/statusreportjudgec1/add', 
			name: 'statusreportjudgec1add', 
			component: () => import('pages/statusreportjudgec1/add.vue'), 
			props: true 
		},
		{ 
			path: '/statusreportjudgec1/edit/:id', 
			name: 'statusreportjudgec1edit', 
			component: () => import('pages/statusreportjudgec1/edit.vue'), 
			props: true 
		},

		
		
//Password reset routes
		{ 
			path: '/index/forgotpassword', 
			name: 'forgotpassword', 
			component: () => import('pages/index/forgotpassword.vue') 
		},
		{ 
			path: '/index/resetpassword', 
			name: 'resetpassword', 
			component: () => import('pages/index/resetpassword.vue') 
		},
		{ 
			path: '/index/resetpassword_completed', 
			name: 'resetpassword_completed', 
			component: () => import('pages/index/resetpassword_completed.vue') 
		},
		
		

		//Error pages
		{ path:  '/error/forbidden', name: 'forbidden',  component: () => import('pages/errors/forbidden.vue') },
		{ path: '/error/notfound',  name: 'notfound', component: () => import('pages/errors/pagenotfound.vue') }
	],
	
	},
	{ path: '/:catchAll(.*)*', component: () => import('pages/errors/pagenotfound.vue') }
];

export default route(async function () {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE == 'history' ? createWebHistory : createWebHashHistory)
	
	const mainRoute = routes.find(x => x.name = "main");
	
	const auth = useAuth();

	
	//get current user data from api on first load
	const { user } = await auth.getUserData();
	if(user){
		const homeRoute = {
			path: '/', 
			alias: '/home', 
			name: 'home', 
			component: () => import('pages/home/home.vue') 
		}
		mainRoute.children.push(homeRoute);
	}
	else{
		const indexRoute = { 
			path: '/',  
			alias: '/home', 
			name: 'index', 
			component: () => import('pages/index/index.vue') 
		}
		mainRoute.children.push(indexRoute);
	}

	const Router = createRouter({
	scrollBehavior(to, from, savedPostion){
		if(savedPostion) return savedPostion;
		return { top:0 }
	},
    routes,
    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })
  return Router
})

